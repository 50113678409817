import React from "react";

import { Row, Col, Table, Spin } from "antd";

import { with_params } from "../utils/params";

//import { get_data_for_xids_list } from "../utils/search_machine";

import { convertToCSVFormat, generateTextFile } from "../utils/CSV_tools";

const leadingZero = (number) => {
  if (number < 10) {
    return "0" + number;
  }
  return String(number);
};

const prepareDate = () => {
  const today = new Date();
  return (
    "" +
    today.getFullYear() +
    leadingZero(today.getMonth() + 1) +
    leadingZero(today.getDate()) +
    "-" +
    leadingZero(today.getHours()) +
    leadingZero(today.getMinutes())
  );
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "Guide",
    dataIndex: "guide",
    key: "guide",
  },
  {
    title: "Passenger",
    dataIndex: "passenger",
    key: "passenger",
  },
  /*
  {
    title: "Structure",
    dataIndex: "structure",
    key: "structure",
  },*/
  {
    title: "Guide Tm (seed) [\xB0C]",
    dataIndex: "guide_tm",
    key: "guide_tm",
    sorter: (a, b) => a.guide_tm - b.guide_tm,
    render: (v) => v.toFixed(2),
  },
  {
    title: "Passenger Tm (seed) [\xB0C]",
    dataIndex: "passenger_tm",
    key: "passenger_tm",
    sorter: (a, b) => a.passenger_tm - b.passenger_tm,
    render: (v) => v.toFixed(2),
  },
  {
    title: "Guide Tm (all) [\xB0C]",
    dataIndex: "guide_tm_all",
    key: "guide_tm_all",
    sorter: (a, b) => a.guide_tm_all - b.guide_tm_all,
    render: (v) => v.toFixed(2),
  },
  {
    title: "Passenger Tm (all) [\xB0C]",
    dataIndex: "passenger_tm_all",
    key: "passenger_tm_all",
    sorter: (a, b) => a.passenger_tm_all - b.passenger_tm_all,
    render: (v) => v.toFixed(2),
  },

  {
    title: "Ends diff [kcal/mol]",
    dataIndex: "ends_diff",
    key: "ends_diff",
    sorter: (a, b) => a.ends_diff - b.ends_diff,
    render: (v) => v.toFixed(2),
  },
];

const CSV_columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Score",
    dataIndex: "score",
    key: "score",
  },
  {
    title: "Head",
    dataIndex: "head",
    key: "head",
  },
  {
    title: "Guide strand",
    dataIndex: "insert",
    key: "insert",
  },
  {
    title: "Middle",
    dataIndex: "middle",
    key: "middle",
  },
  {
    title: "Passenger",
    dataIndex: "insertc",
    key: "insertc",
  },
  {
    title: "Tail",
    dataIndex: "tail",
    key: "tail",
  },
  {
    title: "Structure differences",
    dataIndex: "diffs",
    key: "diffs",
  },
  {
    title: "Tm [deg. C]",
    dataIndex: "antisense_tm",
    key: "Tm",
  },
  {
    title: "Ends diff [kcal/mol]",
    dataIndex: "ends_diff",
    key: "ends_diff",
  },
  {
    title: "Target start",
    dataIndex: "target_start",
    key: "target_start",
  },
  {
    title: "Target end",
    dataIndex: "target_end",
    key: "target_end",
  },
];

const offtarget_columns = [
  {
    title: "Strand",
    dataIndex: "side",
    key: "side",
    sorter: (a, b) => a.side.localeCompare(b.side),
  },
  {
    title: "Alignment",
    dataIndex: "alignment",
    key: "alignment",
  },
  {
    title: "Length",
    dataIndex: "len",
    key: "len",
    sorter: (a, b) => a.len - b.len,
  },
  {
    title: "Mismatches",
    dataIndex: "mismatch",
    key: "mismatch",
    sorter: (a, b) => a.mismatch - b.mismatch,
  },
  {
    title: "Evalue",
    dataIndex: "evalue",
    key: "evalue",
    sorter: (a, b) => a.evalue.localeCompare(b.evalue),
  },
  {
    title: "Accession ID",
    dataIndex: "saccver",
    key: "saccver",
    sorter: (a, b) => a.saccver.localeCompare(b.saccver),
  },
  {
    title: "Title",
    dataIndex: "stitle",
    key: "stitle",
    sorter: (a, b) => a.stitle.localeCompare(b.stitle),
  },
  {
    title: "Region",
    dataIndex: "stype",
    key: "stype",
    sorter: (a, b) => a.stype.localeCompare(b.stype),
  },
  {
    title: "Start",
    dataIndex: "sstart",
    key: "sstart",
    sorter: (a, b) => a.sstart - b.sstart,
  },
  {
    title: "End",
    dataIndex: "send",
    key: "send",
    sorter: (a, b) => a.send - b.send,
  },
];

class Results extends React.Component {
  state = {
    hash: "",
    values: [],
    waiting: true,
    graph_data: { labels: [], datasets: [] },
    additional: {},
    selected_row: -1,
    utr_data: {},
  };

  componentDidMount = () => {
    let { duplexes, /* params*/ } = this.props.location.state;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ duplexes }),
    };

    fetch("https://api.duplexes.sedna.cs.put.poznan.pl", requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response); //DEBUG

        const values = response.result.map((v, vid) => {
          const res = {};
          res.id = vid + 1;
          res.key = vid;
          res.shannon = v.data.shannon;
          res.free_energy = v.data.free_energy;
          res.ends_diff = v.data["5prime_diffs"];
          res.structure = v.data["duplex_structure"];
          res.guide_tm = v.data.guide.tm;
          res.passenger_tm = v.data.passenger.tm;
          res.guide_tm_all = v.data.guide.tm_all
          res.passenger_tm_all = v.data.passenger.tm_all;

          res.guide = v.duplex.guide;
          res.passenger = v.duplex.passenger;

          const pot = (o, oid) => {
            let resu = { ...o };

            const stitle = resu.stitle;

            const stitle_arr = stitle.split(",");

            //resu.stitle = stitle_arr.slice(0,-2).join(",")

            if (
              resu.hasOwnProperty("cdsstart") &&
              resu.hasOwnProperty("cdsend")
            ) {
              const type_arr = [];
              if (resu.sstart < resu.cdsstart) {
                type_arr.push("5'-UTR");
              }
              if (resu.send >= resu.cdsstart && resu.sstart <= resu.cdsend) {
                type_arr.push("CDS");
              }
              if (resu.send > resu.cdsend) {
                type_arr.push("3'-UTR");
              }

              resu.stype = type_arr.join(" / ");
            } else {
              resu.stype = stitle_arr[stitle_arr.length - 1]
                .split(";")[0]
                .trim();
            }

            resu.len = o.sseq.length;

            let original = "";

            if (o.side === "antisense") {
              original = v.duplex.guide;
            } else {
              original = v.duplex.passenger;
            }

            original = original.substring(
              original.length - o.qend,
              original.length - o.qstart + 1
            );

            let align = o.sseq.split("").reverse().join("").replace(/T/g, "U");

            let alignT = [<React.Fragment key={0}>{"3'- "}</React.Fragment>];
            let lineT = "    ";

            let compl = {
              A: "U",
              C: "G",
              G: "C",
              U: "A",
            };

            for (let i = 0; i < align.length; ++i) {
              if (align.charAt(i) === compl[original.charAt(i)]) {
                lineT += "|";
                alignT.push(
                  <React.Fragment key={i + 1}>{align.charAt(i)}</React.Fragment>
                );
              } else {
                lineT += " ";
                alignT.push(
                  <span key={i + 1} style={{ color: "red" }}>
                    {align.charAt(i)}
                  </span>
                );
              }
            }

            alignT.push(
              <React.Fragment key={align.length + 1}>{" -5'"}</React.Fragment>
            );
            lineT += "    ";

            resu["alignment"] = (
              <div style={{ whiteSpace: "pre", fontFamily: "monospace" }}>
                {"5'- " + original + " -3'"}
                <br />
                {lineT}
                <br />
                {alignT}
              </div>
            );

            resu["key"] = oid;

            return resu;
          };

          res.offtargets_human = v.blast_human.map(pot);
          res.offtargets_mouse = v.blast_mouse.map(pot);

          return res;
        });

        this.setState({
          values,
          waiting: false,
        });
      });
  };

  selected_rows_changed = (selectedRowKeys) => {
    const selected_row = selectedRowKeys[0];

    /*const os = this.state.values[selected_row].offtargets

    //console.log(os) //DEBUG

    const id_set = new Set()

    for(const o of os){
      const sacc = o.saccver
      //console.log(o) //DEBUG
      if(!this.state.utr_data.hasOwnProperty(sacc)){
        id_set.add(sacc)
      }
    }

    const arr = Array.from(id_set).slice(0,999)

    get_data_for_xids_list(arr).then(result => {
      console.log(result)
    })

    */
    this.setState({ selected_row });
  };

  print_heatmaps = () => {
    if (this.state.selected_row < 0) {
      return null;
    }

    const sk = this.state.selected_row;
    const row = this.state.values[sk];

    const seq = row.clear_seq;

    const diffs_sh = [];

    for (let i = 0; i < row.shannon.length; ++i) {
      diffs_sh.push(row.shannon[i] - row.natural_shannon[i]);
    }

    const diffs_fe = [];

    for (let i = 1; i < row.free_energy.length; ++i) {
      diffs_fe.push(row.free_energy[i] - row.natural_free_energy[i]);
    }

    const diffs_str = [];

    for (let i = 0; i < row.clear_structure.length; ++i) {
      if (
        row.clear_structure.charAt(i) !== row.clear_natural_structure.charAt(i)
      ) {
        diffs_str.push(1);
      } else {
        diffs_str.push(0);
      }
    }

    const sh_sens = 0.5;
    const fe_sens = 5.0;

    return (
      <Col>
        <Row>
          <h1>
            Shannon entropy (-{sh_sens.toFixed(1)}{" "}
            <div
              style={{
                backgroundImage: "linear-gradient(to right,blue, white, red)",
                display: "inline-block",
                width: "100px",
                height: "25px",
                marginBottom: "-4px",
              }}
            ></div>{" "}
            +{sh_sens.toFixed(1)}), free energy (-{fe_sens.toFixed(1)}{" "}
            <div
              style={{
                backgroundImage: "linear-gradient(to right,blue, white, red)",
                display: "inline-block",
                width: "100px",
                height: "25px",
                marginBottom: "-4px",
              }}
            ></div>{" "}
            +{fe_sens.toFixed(1)}) and structure differences
          </h1>
        </Row>
        <Row>{this.generate_heatmap(seq, diffs_sh, sh_sens)}</Row>
        <Row>{this.generate_heatmap(seq, diffs_fe, fe_sens)}</Row>
        <Row>{this.generate_heatmap(row.clear_structure, diffs_str, 1)}</Row>
      </Col>
    );
  };

  generateCSV = (e) => {
    let str = "";
    for (let i = 0; i < CSV_columns.length; ++i) {
      str += (i > 0 ? "," : "") + convertToCSVFormat(CSV_columns[i].title);
    }
    str += "\r\n";
    for (let v of this.state.values) {
      for (let i = 0; i < CSV_columns.length; ++i) {
        str +=
          (i > 0 ? "," : "") +
          convertToCSVFormat("" + v[CSV_columns[i].dataIndex]);
      }
      str += "\r\n";
    }

    generateTextFile(str, prepareDate() + ".csv");
  };

  generate_heatmap = (seq, arr, lim) => {
    const res = [];

    for (let i = 0; i < seq.length; ++i) {
      let d = arr[i];

      let r = 0,
        g = 0,
        b = 0;

      if (d < 0) {
        if (d < -lim) {
          d = -lim;
        }
        d = (d + lim) / lim;

        r = Math.floor(255 * d);
        g = r;
        b = 255;
      } else {
        if (d > lim) {
          d = lim;
        }
        d = d / lim;

        r = 255;
        g = Math.floor(255 * (1 - d));
        b = g;
      }

      //  0,  0,255
      //255,255,255
      //255,  0,  0

      const colour = "rgb(" + r + "," + g + "," + b + ")";
      res.push(
        <span
          key={i}
          title={arr[i].toFixed(4)}
          style={{
            margin: "1px",
            backgroundColor: colour,
            textAlign: "center",
            fontSize: "14px",
            fontFamily: "monospace",
          }}
        >
          {seq.charAt(i)}
        </span>
      );
    }
    return res;
  };

  generateStructureDrawing = () => {
    const sk = this.state.selected_row;

    if (sk < 0) {
      return null;
    }

    const row = this.state.values[sk];

    const structure = row.structure;
    const guide = row.guide;
    const passenger = row.passenger;

    const ptable = Array(structure.length).fill(-1);

    let stack = [];

    for (let i = 0; i < structure.length; ++i) {
      if (structure.charAt(i) === "(") {
        stack.push(i);
      } else if (structure.charAt(i) === ")") {
        ptable[i] = stack.pop();
        ptable[ptable[i]] = i;
      }
    }

    let last = -1;
    let last_index = ptable.length;

    let new_guide = "";
    let bonds = "";

    for (let i = 0; i < guide.length; ++i) {
      if (ptable[i] >= 0) {
        if (i - last < last_index - ptable[i]) {
          for (let j = 0; j < last_index - ptable[i] + last - i; ++j) {
            new_guide += "-";
            bonds += " ";
          }
        }

        last = i;
        last_index = ptable[i];

        bonds += "|";
      } else {
        bonds += " ";
      }
      new_guide += guide.charAt(i);
    }

    let new_passenger = "";

    last = structure.length;
    last_index = -1;

    for (let i = structure.length - 1; i >= guide.length; --i) {
      if (ptable[i] >= 0) {
        if (last - i < ptable[i] - last_index) {
          for (let j = 0; j < ptable[i] - last_index - last + i; ++j) {
            new_passenger += "-";
          }
        }

        last = i;
        last_index = ptable[i];
      }
      new_passenger += passenger.charAt(i - guide.length);
    }

    return (
      <div style={{ whiteSpace: "pre", fontFamily: "monospace" }}>
        {"5'- " + new_guide + " -3'"}
        <br />
        {"    " + bonds}
        <br />
        {"3'- " + new_passenger + " -5'"}
      </div>
    );
  };

  render = () => (
    <Row>
      <Col span={20} offset={2}>
        <h1>Results</h1>
        {this.state.waiting ? (
          <React.Fragment>
            <Row>
              <div style={{ height: "100px" }}></div>
            </Row>
            <Row>
              <Spin
                style={{ width: "100%" }}
                className="ant-spin-vlg"
                tip="Loading..."
              />
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row>
              <Table
                style={{ width: "100%" }}
                columns={columns}
                dataSource={this.state.values}
                rowSelection={{
                  type: "radio",
                  onChange: this.selected_rows_changed,
                }}
              />
            </Row>
            <Row>
              <div style={{ height: "30px" }}></div>
            </Row>
            {/*<Row>
              <Button onClick={this.generateCSV}>Export to CSV</Button>
            </Row>*/}
            <Row>
              <div style={{ height: "100px" }}></div>
            </Row>
            <Row>
              {/*
              <span
                style={{
                  backgroundColor: "lightblue",
                  fontFamily: "monospace",
                }}
              >
                ACGU
              </span>{" "}
              - unavailable because of the 2D structure*/}
            </Row>
            {/*<Row>
              <b style={{ color: "red", fontFamily: "monospace" }}>ACGU</b> -
              off-targets detected
            </Row>*/}
            {/*<Row>
              <div style={{ height: "20px" }}></div>
            </Row>
            <Row>
              <div style={{ height: "100px" }}></div>
            </Row>*/}
            <Row>{this.generateStructureDrawing()}</Row>
            <Row>
              <div style={{ height: "50px" }}></div>
            </Row>
            {this.state.selected_row < 0 ? null : (
              <Row>
                <Col span={24}>
                  {/*<Row>{this.print_heatmaps()}</Row>*/}
                  <Row>
                    <div style={{ height: "100px" }}></div>
                  </Row>
                  <Row>
                    <Table
                      style={{ width: "100%" }}
                      columns={offtarget_columns}
                      dataSource={
                        this.state.values[this.state.selected_row].offtargets_human
                      }
                    />
                  </Row>
                </Col>
              </Row>
            )}
            <Row>
              <div style={{ height: "50px" }}></div>
            </Row>
            {this.state.selected_row < 0 ? null : (
              <Row>
                <Col span={24}>
                  {/*<Row>{this.print_heatmaps()}</Row>*/}
                  <Row>
                    <div style={{ height: "100px" }}></div>
                  </Row>
                  <Row>
                    <Table
                      style={{ width: "100%" }}
                      columns={offtarget_columns}
                      dataSource={
                        this.state.values[this.state.selected_row].offtargets_mouse
                      }
                    />
                  </Row>
                </Col>
              </Row>
            )}

          </React.Fragment>
        )}
      </Col>
    </Row>
  );
}

export default with_params(Results);
